import Api from '../helpers/Api';
import { modules } from '../constants';

export const contractsData = {
  namespaced: true,
  state: {
    contracts: [],
    contractTypeForLoad: 2,
    docTypes: [],
    documents: [],
    conclusions: [],
    calculations: [],
    services: [],
    acts: [],
    actsSummary: {
      actsCommon: 0,
      payCommon: 0,
    },
    managers: [],
    possibleAgents: [],
    inn: null,
    contacts: [],
    currentContract: '',
    actsProcessing: false,
    processing: false,
    contactsTypes: [
      {
        id: 1,
        label: 'Телефон',
      },
      {
        id: 2,
        label: 'E-mail',
      },
      // {
      //   id: 3,
      //   label: 'Telegram',
      // },
      {
        id: 4,
        label: 'Учетка',
      },
    ],
    subDoers: [],
    subdoersBinding: {
      subdoer: null,
      services: [],
      tempServices: [],
    },
    paymentList: [],
    checkedActs: [],
    checkedPayments: [],
    actHighlightParams: null,
  },
  actions: {
    async getContractsList({
      state, commit, dispatch, rootState,
    }) {
      if (!rootState.user.selectedCompany) {
        return;
      }
      commit('PROCESSING', true);
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'GET:contracts_list';
      data.archive = state.contractTypeForLoad;
      const contracts = await Api.get(data);
      if (contracts.body) {
        if (contracts.body[0].err === 1) {
          commit('SET_CONTRACTS', []);
        } else {
          await dispatch('getDocTypes');
          await dispatch('getResponsiblings');
          commit('SET_CONTRACTS', contracts.body);
          commit('SET_DOCUMENTS', []);
          commit('SET_CURRENT_CONTRACT', '');
        }
        commit('PROCESSING', false);
        return contracts.body.length;
      }
      commit('PROCESSING', false);
      return false;
    },
    async getResponsiblings({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'GET:responsiblings_json';
      data.what_for = 0;
      data.id_people = 0;
      const contactsList = await Api.get(data);
      if (contactsList) {
        commit('SET_CONTACTS_LIST', contactsList ? contactsList.body : []);
      }
      return contactsList;
    },
    // async getResponsiblingsJson({ rootState }) {
    //   const data = { ...rootState.user.constantParams };
    //   data.Name_module = modules.calculation;
    //   data.id_org = rootState.user.selectedCompany.id_org;
    //   data.Name_event = 'GET:responsiblings_json';
    //   const result = await Api.get(data);
    //   return result.body;
    // },
    async getDocuments({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.id_org = rootState.user.selectedCompany.id_org;
      data.id_contract = id;
      data.Name_event = 'GET:doc_list';
      const docList = await Api.get(data);
      if (docList.errorType === 0) {
        commit('SET_DOCUMENTS', docList.body);
        return docList.body.length;
      }
      return false;
    },
    async getConclusions({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.id_org = rootState.user.selectedCompany.id_org;
      data.id_contract = id;
      data.Name_event = 'GET:ZA_doc_list';
      const docList = await Api.get(data);
      if (docList.errorType === 0) {
        commit('SET_CONCLUSIONS', docList.body);
        return docList.body.length;
      }
      return false;
    },
    async getServices({ commit, rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'GET:services_list';
      const servList = await Api.get(data);
      if (servList.body && servList.errorType === 0) {
        commit('SET_SERVICES', servList.body);
        return true;
      }
      return false;
    },
    async removeDocument({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.Name_event = 'del_doc';
      data.id_doc = params.id;
      const remove = await Api.post(data, true);
      if (remove.body && remove.body[0].err === 0) {
        if (params.contractId) dispatch('getDocuments', params.contractId);
        return true;
      }
      return false;
    },
    async sendDocToContact({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.contacts;
      data.Name_event = 'POST:send_doc_to_contact';
      data.id_contact = params.contactId;
      data.id_doc = params.docId;
      data.txt_note = params.txtNote;
      data.copy_me = params.copyMe ? 1 : 0;
      const send = await Api.post(data, true);
      if (send.body && send.body.length && send.errorType === 0) {
        return true;
      }
      return false;
    },
    async getDocTypes({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.Name_event = 'GET:doc_types';
      const docTypes = await Api.get(data);
      commit('SET_DOC_TYPES', docTypes.body);
      return docTypes;
    },
    async createDoc({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      const additionalParams = {
        doc_type: params.type,
        do_now: 1,
        id_contract: params.contractId,
        id_spec: data.id_login,
        id_template: params.templateId,
        file_name: params.fileName,
      };
      data.Name_module = modules.medAdmin;
      data.Name_event = 'pdf_make_one_json';
      data.str_param = JSON.stringify(additionalParams);
      const create = await Api.post(data, true);
      if (create.body && !create.body.error) {
        dispatch('getDocuments', params.contractId);
        return true;
      }
      return false;
    },
    async uploadFile({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      const uploadParams = JSON.stringify({
        Name_app: data.Name_app,
        id_login: data.id_login,
        id_patient: params.contractId,
        id_doc_type: params.typeId,
        file_name: 'testFileName.jpg',
      });
      const formData = new FormData();
      formData.append('imgUploader', params.file, params.file.name);
      formData.append('submit', 'upload');
      formData.append('json', `${uploadParams}`);
      const upload = await Api.uploadFile(formData, 'upload');
      if (upload && upload.statusText === 'OK') {
        return true;
      }
      return false;
    },
    async saveContactNote({ commit, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.contacts;
      data.Name_event = 'POST:contact_note';
      data.id_contact = params.contactId;
      data.txt_note = params.text;
      const saveNote = await Api.post(data, true);
      if (saveNote.errorType === 0) {
        commit('SAVE_CONTACT_NOTE', params);
      }
    },
    async correctContact({ commit, rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.contacts;
      data.Name_event = 'POST:contact_correct';
      data.id_contact = params.id_contact;
      data.type_correct = params.type_correct;
      const correction = await Api.post(data, true);
      if (correction.body.length) {
        commit('CORRECT_CONTACT', params);
        dispatch('getResponsiblings');
        return true;
      }
      return false;
    },
    async saveContact({ rootState, dispatch }, params) {
      let data = { ...rootState.user.constantParams };
      data = Object.assign(data, params);
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_module = modules.contacts;
      data.Name_event = 'POST:contact_add_for_org';
      const result = await Api.post(data, true);
      if (result) {
        dispatch('getResponsiblings');
        return result;
      }
      return false;
    },
    async saveContract({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:contract';
      const result = await Api.post(data, true);
      if (result.errorType === 0 && result.body.length) {
        return true;
      }
      return false;
    },
    async getAgents({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:agents_by_contract';
      data.id_contract = id;
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.get(data);
      if (result.body) {
        commit('SET_MANAGERS', result.body);
      }
      // return result.body;
    },
    async getPossibleAgents({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:agents_full_list';
      data.id_contract = id;
      const result = await Api.get(data);
      if (result.body) {
        commit('SET_POSSIBLE_AGENTS', result.body);
      }
    },
    async addAgent({ rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data = Object.assign(data, params);
      data.Name_event = 'POST:agent_add';
      const result = await Api.post(data, true);
      if (result && result.body[0].err === 0) {
        return true;
      }
      return result.body[0];
    },
    async getPaymentList({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:payment_list';
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.get(data);
      if (result.body) {
        commit('SET_PAYMENT_LIST', result.body);
      }
    },
    async getActs({ commit, rootState }, id) {
      commit('ACTS_PROCESSING', true);
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:act_list';
      data.id_contract = id;
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.get(data);
      if (result.body) {
        commit('SET_ACTS', result.body);
      }
      commit('ACTS_PROCESSING', false);
    },
    async getActsPatients({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.id_contract = params.contractId;
      data.id_act = params.actId;
      data.Name_event = 'GET:patient_list_by_act';
      await Api.get(data);
    },
    async setContractConditions({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.calculation;
      data.Name_event = 'POST:contract_conditions';
      data.txt = params.param;
      data.id_contract = params.contractId;
      const result = await Api.post(data, true);
      if (result.body) {
        return result.body[0];
      }
      return false;
    },
    async getTemplate({ rootState }, typeId) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:contracts_shablon';
      data.type_contract = typeId;
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.get(data);
      if (result?.body?.length) {
        return result.body;
      }
      return [];
    },
    async setServicePrice({ commit, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:service_change';
      data.price = params.newText;
      if (!params.newText) {
        data.price = '0';
      }
      data.id_service_contract = params.item.id_service_contract;
      const result = await Api.post(data, true);
      if (result?.body && !result?.body[0].err) {
        const newData = {
          price: params.newText,
          oldId: params.item.id_service_contract,
          newId: result.body[0].id_service_contract,
        };
        commit('SET_SERVICE_PRICE', newData);
        return true;
      }
      if (result?.body[0].err) {
        return result?.body[0];
      }
      return false;
    },
    async deleteServiceFromContract({ state, rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:service_del';
      data.json_str = params;
      const send = await Api.post(data, true);
      if (send) {
        dispatch('getServices', { id_contract: state.currentContract });
        return true;
      }
      return false;
    },
    async getContractsInTree({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'GET:contracts_in_tree';
      const result = await Api.post(data, true);
      if (result.body) {
        return result.body;
      }
    },
    async saveContractNote({ commit, rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data.Name_module = 'WEB_managers';
      data.Name_event = 'POST:contract_note';
      data = Object.assign(data, params);
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        commit('CHANGE_CONTRACT_NOTE', params);
        return true;
      }
      return false;
    },
    async getCalculations({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:contract_calculations';
      data.Name_module = modules.calculation;
      data.id_contract = id;
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.get(data, true);
      if (result.body) {
        commit('SET_CALCULATIONS', result.body);
      }
    },
    async setDefaultCalculation({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:calculation_set_default';
      data.Name_module = modules.calculation;
      data.id_calc = params.id;
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        await dispatch('getCalculations', params.contractId);
        return true;
      }
      return false;
    },
    async changeCalculation({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.total_price = 0;
      data.avg_price = 0;
      Object.assign(data, params);
      data.Name_event = 'POST:calculation_change';
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        return true;
      }
      return false;
    },
    async getSubDoers({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:sub_doers';
      data.id_contract = id;
      const result = await Api.get(data, true);
      if (result.body) {
        commit('SET_SUBDOERS', result.body);
      }
      return false;
    },
    async bindSubdoers({ state, dispatch, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:sub_doers';
      data.id_contract = id;
      data.id_contract_sub_doer = state.subdoersBinding.subdoer;
      data.json_str_services = JSON.stringify(state.subdoersBinding.services);
      data.json_str = '';
      const result = await Api.post(data, true);
      if (result.body && result.body[0].err === 0) {
        dispatch('getServices', { id_contract: id });
        return true;
      }
      return false;
    },
    async bindPayment({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:bind_act_pay';
      const result = await Api.post(data, true);
      if (result.body && result.body.length) {
        return result.body[0];
      }
      return false;
    },
    async unbindPayment({ rootState }, idActPay) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:unbind_act_pay';
      data.id_act_pay = idActPay;
      const result = await Api.post(data, true);
      if (result.body && result.body[0].err === 0) {
        return true;
      }
      return false;
    },
    async getDocSendLog({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = modules.contacts;
      data.Name_event = 'GET:doc_send_log';
      data.id_doc = id;
      const result = await Api.get(data);
      if (result.body) {
        return result.body;
      }
    },
    async approveAct({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.id_act = id;
      data.Name_event = 'POST:act_approve';
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        return true;
      }
      return false;
    },
    async createAct({ rootState }) {
      const data = { ...rootState.user.constantParams };
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'POST:acts_add_automatically';
      const result = await Api.post(data, true);
      if (result.body && result.body.length) {
        return result.body[0];
      }
    },
    async cancelAct({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.id_act = id;
      data.Name_event = 'POST:act_del';
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        return true;
      }
      return false;
    },
    async mergeActs({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:act_merge';
      const result = await Api.post(data, true);
      if (result.errorType === 0) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    SET_CONTRACT_TYPE_FOR_LOAD(state, type) {
      if (state.contractTypeForLoad === 0) {
        return;
      }
      state.contractTypeForLoad = type;
    },
    SET_POSSIBLE_AGENTS(state, agents) {
      state.possibleAgents = agents;
    },
    SET_ACTS_SUMMARY(state, sums = null) {
      if (!sums) {
        state.actsSummary = {
          actsCommon: 0,
          payCommon: 0,
        };
      }
      state.actsSummary = sums;
    },
    SET_SERVICE_PRICE(state, data) {
      const service = state.services.find((item) => item.id_service_contract === data.oldId);
      if (service) {
        service.price = data.price;
        service.id_service_contract = data.newId;
      }
    },
    RESET_CONTRACT_DATA(state) {
      state.currentContract = '';
      state.documents = [];
      state.services = [];
      state.acts = [];
      state.conclusions = [];
    },
    SET_CONTRACTS(state, contracts) {
      state.contracts = contracts;
      state.contracts.forEach((i) => {
        if (i.id_tree) {
          const tmp = JSON.parse(i.id_tree);
          i.id_tree = tmp;
        }
      });
      this.commit('contractsData/RESET_CONTRACT_DATA');
    },
    SET_SINGLE_CONTRACT(state, contract) {
      let mutableContract = state.contracts.find((item) => item.id_contract === contract.id_contract);
      mutableContract = contract;
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_CONCLUSIONS(state, conclusions) {
      state.conclusions = conclusions;
    },
    SET_SERVICES(state, services) {
      state.services = services;
    },
    SET_ACTS(state, acts) {
      state.acts = acts;
    },
    SET_PAYMENT_LIST(state, paymentList) {
      state.paymentList = paymentList;
    },
    SET_MANAGERS(state, managers) {
      state.managers = managers;
    },
    SET_CONTACTS_LIST(state, contacts) {
      state.contacts = contacts;
    },
    SET_DOC_TYPES(state, docTypes) {
      docTypes.forEach((item) => {
        if (item.templates) {
          const children = JSON.parse(item.templates);
          item.children = children;
        }
      });
      state.docTypes = docTypes;
    },
    SAVE_CONTACT_NOTE(state, params) {
      const contact = state.contacts.find((item) => item.id_contact === params.contactId);
      if (contact) {
        contact.note = params.text;
      }
    },
    SET_CURRENT_CONTRACT(state, id) {
      state.currentContract = id;
    },
    ACTS_PROCESSING(state, payload) {
      state.actsProcessing = payload;
    },
    PROCESSING(state, payload) {
      state.processing = payload;
    },
    CORRECT_CONTACT(state, params) {
      const contact = state.contacts.find((item) => item.id_contact === params.id_contact);
      if (contact) {
        switch (params.type_correct) {
          case 1:
            contact.invalid = 1;
            break;
          case 2:
            contact.invalid = 2;
            break;
          case 3:
            contact.invalid = 3;
            break;
          case 4:
            contact.main = true;
            break;
          default:
            break;
        }
      }
    },
    CHANGE_CONTRACT_NOTE(state, params) {
      const note = JSON.parse(params.json_str);
      const contract = state.contracts.find(
        (item) => item.id_contract.toString() === params.id_contract,
      );
      if (contract) {
        const field = Object.keys(note)[0];
        // eslint-disable-next-line prefer-destructuring
        contract[field] = Object.values(note)[0];
      }
    },
    SET_CALCULATIONS(state, calculations) {
      state.calculations = calculations;
    },
    SET_SUBDOERS(state, subDoers) {
      state.subDoers = subDoers;
    },
    BIND_SUBDOER(state, subdoer) {
      state.subdoersBinding.subdoer = subdoer;
    },
    BIND_SERVICES(state, services) {
      state.subdoersBinding.services = services;
    },
    SET_TEMP_SERVICES(state, services) {
      state.subdoersBinding.tempServices = services;
    },
    SET_CHECKED_ACTS(state, acts) {
      state.checkedActs = acts;
    },
    SET_CHECKED_PAYMENTS(state, payments) {
      state.checkedPayments = payments;
    },
    SET_ACT_HIGHLIGHT_PARAMS(state, params) {
      state.actHighlightParams = params;
    },
  },
  getters: {
    getContracts(state) {
      return state.contracts;
    },
    getCurrentContract(state) {
      return state.currentContract;
    },
    getCurrentContractName(state) {
      const currentContract = state.contracts.find((item) => item.id_contract.toString() === state.currentContract);
      return currentContract?.name_contract;
    },
    getContractsCount(state) {
      return state.contracts.length + 1;
    },
    getDocuments(state) {
      return state.documents;
    },
    getConclusions(state) {
      return state.conclusions;
    },
    getResponsiblings(state) {
      return state.contacts;
    },
    getEmails(state) {
      const realContacts = state.contacts.map((item) => {
        try {
          const result = JSON.parse(item.contacts).flat()[0];
          result.fio = item.fio;
          return result;
        } catch {
          return null;
        }
      }).flat();
      return realContacts.filter((item) => item.value_type === 'e-mail');
    },
    getDocTypes(state) {
      return state.docTypes;
    },
    getContractById: (state) => (id) => state.contracts.find((item) => item.id_contract === +id),
    getContactsTypes(state) {
      return state.contactsTypes;
    },
    getServices(state) {
      return state.services;
    },
    getActs(state) {
      return state.acts;
    },
    getPaymentList(state) {
      return state.paymentList;
    },
    getManagers(state) {
      return state.managers;
    },
    getPossibleAgents(state) {
      return state.possibleAgents;
    },
    getCalculations(state) {
      return state.calculations;
    },
    getPossibleToBind(state) {
      return state.subdoersBinding.subdoer && state.subdoersBinding.services.length;
    },
    getCurrentSubdoer(state) {
      return state.subdoersBinding.subdoer;
    },
    getSubdoersBinding(state) {
      if (!state.subdoersBinding.services.length) {
        return false;
      }
      const id = state.subdoersBinding.services[0];
      const selectedService = state.services.find((service) => service.id_service === id);
      if (selectedService) {
        const { binded } = selectedService;
        if (binded) {
          const subdoersArr = JSON.parse(selectedService.prices_sub_doer_json).map(
            (it) => it.id_contract_sub_doer,
          );
          if (subdoersArr.includes(state.subdoersBinding.subdoer)) {
            return binded;
          }
        }
        return selectedService.binded;
      }
    },
    getCheckedActs(state) {
      return state.checkedActs;
    },
    getCheckedPayments(state) {
      return state.checkedPayments;
    },
  },
};
