<template>
  <div class="org-editor">
    <mcw-dialog-title>
      {{ title }}
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close" />
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
      <div class="org-editor__base" v-if="!sync">
        <mcw-textfield
          v-model.trim="query"
          :label="!inn ? 'Введите ИНН или ОГРН' : 'ИНН'"
          minlength="10"
          maxlength="15"
          required
          outline
          :disabled="!!inn"
          :valid="isValid"
          helptext="от 10 до 15 цифр"
          helptext-validation
        />
        <mcw-menu-anchor>
          <mcw-menu v-model="showOrgsSelect" v-if="orgsForSelect.length">
            <ul class="mdc-menu__selection-group">
              <mcw-list-item>Выберите нужную организацию:</mcw-list-item>
              <li class="mdc-list-divider" role="separator"></li>
              <mcw-list-item
                v-for="org in orgsForSelect"
                :key="org.value"
                @click="selectOrganization(org.data)"
              >
                <!-- <mcw-material-icon
                    v-if="existingOrgCompare(org.value)"
                    icon="done"
                    style="color: green">
                  </mcw-material-icon> -->
                {{ org.value }}
              </mcw-list-item>
            </ul>
          </mcw-menu>
        </mcw-menu-anchor>
        <div class="org-editor__actions">
          <mcw-menu-anchor>
            <mcw-button v-if="isOrgExists && agents.length" @click="showAgents = true">
              менеджеры
            </mcw-button>
            <mcw-menu v-model="showAgents">
              <mcw-list-item v-for="(item, index) in agents" :key="index">
                <span :style="{ fontWeight: item.current_agent ? 'bold' : 'normal' }">
                  {{ item.agent }}
                </span>
              </mcw-list-item>
            </mcw-menu>
          </mcw-menu-anchor>
          <mcw-button
            v-if="isOrgExists && this.allowedCreateContract"
            :disabled="!this.selectedContractType || !this.selectedDoer"
            @click="addContract"
          >
            добавить договор
          </mcw-button>
        </div>
        <mcw-textfield
          class="add-org__result"
          disabled
          outline
          label="Краткое наименование"
          v-model="shortName"
        />
        <div class="org-editor__org-found" v-if="isOrgExists && !inn">
          Организация уже имеется среди наших контрагентов!
        </div>
        <mcw-textfield
          class="add-org__result"
          rows="4"
          cols="40"
          multiline
          disabled
          outline
          label="Полное наименование"
          v-model="fullName"
        />
        <NewContractParams
          :contractType="selectedContractType"
          :doer="selectedDoer"
          @selectType="selectType"
          @selectDoer="selectDoer"
        />
      </div>
      <div class="org-editor__compare" v-else>
        <mcw-layout-grid>
          <mcw-layout-cell desktop="6"></mcw-layout-cell>
          <mcw-layout-cell desktop="6">
            <div class="org-editor__renew">
              <mcw-button @click="actualizeData()">
                обновить данные из dadata
              </mcw-button>
              <span>(это платно, без надобности не вызывать)</span>
            </div>
          </mcw-layout-cell>
          <mcw-layout-cell desktop="6">
            <div class="add-org__compare-wrapper" v-if="fieldsToCompare">
              <h3>Данные Medsafe:</h3>
              <div v-for="(value, name, index) in fieldsToCompare.bd_fields" :key="index">
                <div v-if="!fieldsToEdit.includes(name)">
                  <b> {{ fieldsToCompare.name_titles[name] }} </b>: {{ value }}
                </div>
                <div v-else class="org-editor__compare-field">
                  <b> {{ fieldsToCompare.name_titles[name] }} </b>:
                  <EditableCell
                    :item="{
                      name,
                      value
                    }"
                    editableField="value"
                    width="200"
                    @saveEditableCell="saveCustomField"
                  />
                </div>
              </div>
            </div>
          </mcw-layout-cell>
          <mcw-layout-cell desktop="6">
            <div
              class="add-org__compare-wrapper"
              v-if="fieldsToCompare && fieldsToCompare.dadata_fields"
            >
              <h3>Данные DaData.ru:</h3>
              <div v-for="(value, name, index) in fieldsToCompare.dadata_fields" :key="index">
                <b>{{ fieldsToCompare.name_titles[name] }}</b
                >: {{ value }}
              </div>
            </div>
          </mcw-layout-cell>
        </mcw-layout-grid>
        <mcw-button
          class="org-editor__fab"
          v-tooltip="{ content: 'Обновить все данные Medsafe данными из DaData.ru' }"
          @click="syncTables"
        >
          <mcw-material-icon
            icon="arrow_circle_left"
            class="org-editor__fab-icon"
          ></mcw-material-icon>
        </mcw-button>
      </div>
    </mcw-dialog-content>

    <mcw-dialog-footer>
      <mcw-button
        class="show-sync-button"
        v-if="(shortName || fullName) && isOrgExists && !sync"
        @click="showSyncLayout()"
      >
        Детали организации
      </mcw-button>
      <mcw-dialog-button
        v-if="!isOrgExists"
        :disabled="!isPossibleToAdd"
        @click="addOrganization()"
      >
        добавить
      </mcw-dialog-button>
      <mcw-dialog-button v-if="!sync" @click="$emit('close')">отмена</mcw-dialog-button>
      <mcw-dialog-button v-else @click="sync = false">назад</mcw-dialog-button>
    </mcw-dialog-footer>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { ORG_FIELDS_TO_EDIT } from '@/constants';
import EditableCell from '@/components/EditableCell.vue';
import { utils } from '../helpers/utils';
import NewContractParams from './NewContractParams.vue';
import Spinner from './Spinner.vue';

const regExp = /^\d{10,15}$/;

export default {
  name: 'OrgEditor',
  components: {
    NewContractParams,
    Spinner,
    EditableCell,
  },
  mixins: [utils],
  props: ['inn'],
  emits: ['close'],
  data() {
    return {
      query: '',
      shortName: '',
      fullName: '',
      sync: false,
      agents: [],
      selectedContractType: '',
      selectedDoer: '',
      isOrgExists: false,
      isLoading: false,
      fieldsToCompare: null,
      idOrg: null,
      fewOrgs: false,
      tin: null,
      jsonData: '',
      showAgents: false,
      orgsForSelect: [],
      allowedCreateContract: 0,
      showOrgsSelect: false,
    };
  },
  computed: {
    title() {
      return this.inn ? 'Подробности организации' : 'Добавить организацию';
    },
    isValid() {
      return regExp.test(this.query);
    },
    isPossibleToAdd() {
      return this.selectedContractType && this.selectedDoer && this.tin;
    },
    fieldsToEdit() {
      return ORG_FIELDS_TO_EDIT;
    },
  },
  watch: {
    query() {
      this.requestOrgData();
    },
    orgsForSelect() {
      if (this.orgsForSelect.length) {
        this.showOrgsSelect = true;
      }
    },
  },
  created() {
    if (this.inn) {
      this.query = this.inn;
    }
  },
  methods: {
    existingOrgCompare(org) {
      if (this.orgFound.toLowerCase() === org.toLowerCase()) {
        return true;
      }
      return false;
    },
    selectOrganization(orgData) {
      this.setValues(orgData);
      this.addOrganization();
      this.showOrgsSelect = false;
    },
    selectType(type) {
      this.selectedContractType = type;
    },
    selectDoer(doer) {
      this.selectedDoer = doer;
    },
    async getOrgDataSyncFields() {
      const orgData = {
        id_org: this.idOrg,
        json_str: '',
      };
      const syncFields = await this.$store.dispatch('orgsData/getOrgsSyncFields', orgData);
      this.fieldsToCompare = syncFields;
    },
    async showSyncLayout() {
      if (!this.fieldsToCompare) {
        this.isLoading = true;
        await this.getOrgDataSyncFields();
        this.isLoading = false;
      }
      this.sync = !this.sync;
    },
    async requestOrgData() {
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      const check = await this.$store.dispatch('orgsData/getExistedOrg', { tin: this.query });
      if (check && check.id_org) {
        this.isOrgExists = true;
        this.idOrg = check.id_org;
        this.allowedCreateContract = check.allowed_create_contract;
        try {
          this.fieldsToCompare = {
            bd_fields: JSON.parse(check.bd_fields),
            dadata_fields: JSON.parse(check.dadata_fields),
            name_titles: JSON.parse(check.name_titles),
          };
          this.fullName = this.fieldsToCompare.bd_fields.Names;
          this.shortName = this.fieldsToCompare.bd_fields.shortName;
          const agents = JSON.parse(check.agents);
          this.agents = agents;
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
        return false;
      }

      const orgData = await this.getExternalData();
      if (orgData.length === 1) {
        const { data } = orgData[0];
        this.setValues(data);
      } else {
        this.orgsForSelect = orgData;
      }
      this.isLoading = false;
    },
    async getExternalData() {
      const orgData = await this.$store.dispatch('orgsData/getOrgByTin', this.query);
      if (!orgData || !orgData.length) {
        this.emitter.emit('message', 'Не удается найти организацию');
      } else {
        return orgData;
      }
    },
    async actualizeData() {
      let dadata = await this.getExternalData();

      if (dadata.length > 1) {
        const { kpp } = this.fieldsToCompare.bd_fields;
        dadata = dadata.filter((item) => item.data.kpp === kpp);
      }

      const dataForRenew = dadata[0] || null;

      if (dataForRenew) {
        const params = {
          id_org: this.idOrg,
          json_str: JSON.stringify(dataForRenew.data),
        };
        const refreshedData = await this.$store.dispatch('orgsData/postOrgsSyncFields', params);
        if (refreshedData) {
          this.fieldsToCompare = refreshedData;
        }
      } else {
        this.emitter.emit('message', 'Не удалось обновить данные об организации');
      }
    },
    setValues(data) {
      this.fullName = data.name.full_with_opf || '';
      this.shortName = data.name.short || '';
      this.tin = data.inn;
      this.jsonData = JSON.stringify(data);
    },
    async syncTables() {
      // const data = {
      //   id_org: this.idOrg,
      //   json_str: this.jsonData,
      // };
      // await this.$store.dispatch('orgsData/postOrgsSyncFields', data);
    },
    async addOrganization() {
      if (!this.selectedDoer || !this.selectedContractType) {
        return;
      }
      const params = {
        inn: this.tin,
        id_doer: this.selectedDoer,
        type_contract: this.selectedContractType,
        json_str: this.jsonData,
      };
      const newOrg = await this.$store.dispatch('orgsData/addNewOrg', params);
      if (newOrg.errorType && newOrg.errorType !== 0) {
        this.emitter.emit('message', 'Не удалось создать организацию!');
      } else {
        if (newOrg.body && newOrg.body[0].new_org) {
          const newOrgId = newOrg.body[0].new_org;
          this.emitter.emit('message', 'Организация успешно добавлена');
          const companiesList = await this.$store.dispatch('user/getCompaniesList');
          if (companiesList) {
            this.emitter.emit('selectNewOrg', newOrgId);
            this.$store.dispatch('user/companiesList');
          }
          this.$emit('close');
        } else {
          const agents = newOrg.map((item) => item.value);
          this.existingData = this.arrayUnique(agents, 'id_people');
          this.isOrgExists = true;
        }
        this.tin = null;
      }
    },
    async addContract() {
      const json = {
        id_org_doer: this.selectedDoer,
        type_contract: this.selectedContractType,
        id_org_customer: this.idOrg,
      };
      const params = {
        id_contract: 0,
        json_str: JSON.stringify(json),
      };
      const addContract = await this.$store.dispatch('contractsData/saveContract', params);
      if (addContract) {
        this.$store.dispatch('contractsData/getContractsList');
        this.selectedContractType = '';
        this.selectedDoer = '';
        this.emitter.emit('message', 'Договор успешно добавлен');
      } else {
        this.emitter.emit('message', 'Не удалось добавить договор');
      }
    },
    async saveCustomField(data) {
      const params = {
        tin: this.query,
        json_params: JSON.stringify({
          [data.item.name]: data.newText,
        }),
      };
      await this.$store.dispatch('orgsData/getExistedOrg', params);
    },
  },
};
</script>

<style lang="scss">
.org-editor {
  & .mdc-dialog__content {
    overflow-y: visible !important;
  }
  &__org-exists-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & h3 {
      text-align: right;
    }
  }
  &__form .select-wrapper {
    margin-top: 10px;
  }
  &__result .mdc-text-field__input {
    color: #000 !important;
  }
  &__org-found {
    color: green;
    padding: 10px;
  }
  &__compare {
    position: relative;
  }
  &__compare-field {
    display: flex;
    align-items: center;
  }
  &__fab {
    position: absolute !important;
    top: 50%;
    left: calc(50% - 24px);
  }
  &__fab-icon {
    font-size: 48px;
    color: green !important;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  & .mdc-menu-surface {
    width: max-content;
  }
}
</style>
