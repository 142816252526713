import Api from '../helpers/Api';
import DaData from '../helpers/DaData';
import { modules } from '../constants';

export const orgsData = {
  namespaced: true,
  state: {
    orgJson: '',
    contractTypes: null,
    doers: [
      {
        id_org: 1,
        org_name: 'МЦ Эко-безопасность',
      },
    ],
    fullOrgsList: [],
  },
  actions: {
    async getContractTypes({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:contract_types';
      const result = await Api.get(data);
      if (result.body && !result.body.err) {
        commit('SET_CONTRACT_TYPES', result.body);
      }
    },
    async getDoers({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:doers';
      const result = await Api.get(data);
      if (result.body && !result.body.err) {
        commit('SET_DOERS', result.body);
      }
    },
    async getOrgByTin({ commit }, query) {
      const daData = new DaData(query);
      const result = await daData.getOrganizationByTin();
      if (result) {
        const orgJson = JSON.parse(result).suggestions;
        commit('SET_ORG_JSON', JSON.stringify(orgJson));
        return orgJson;
      }
      return false;
    },
    async getExistedOrg({ rootState }, params) {
      const constantParams = { ...rootState.user.constantParams };
      const data = Object.assign(constantParams, {});
      data.Name_event = 'POST:fact_exist_org';
      data.inn = params.tin;
      if (params.json_params) {
        data.json_params = params.json_params;
      }
      const result = await Api.post(data, true);
      if (result.body[0].names && result.body.length === 1) {
        return result.body[0];
      }
      return false;
    },
    async addNewOrg({ dispatch, rootState }, params) {
      const constantParams = { ...rootState.user.constantParams };
      const data = Object.assign(constantParams, params);
      data.Name_event = 'POST:orgaization_by_dadata';
      const newOrg = await Api.sendLargeJson(data);
      if (newOrg.body && newOrg.body[0].new_org) {
        return newOrg;
      }
      if (newOrg.body && newOrg.body[0].new_org === 0) {
        return Promise.allSettled(newOrg.body.map((item) => dispatch('getEmail', item)))
          .then((results) => {
            const agentList = [];
            results.forEach((result) => agentList.push(result));
            return agentList;
          })
          .catch((error) => console.log(error));
      }
      return false;
    },
    async getEmail({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_module = modules.contacts;
      data.Name_event = 'GET:contacts_list';
      const result = await Api.get(data);
      if (result.body) {
        return result.body;
      }
    },
    async getOrgsSyncFields({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'GET:orgdata_sync_fields';
      const result = await Api.sendLargeJson(data);
      if (result) {
        const fields = result.body[0];
        let tmp = JSON.parse(result.body[0].bd_fields);
        fields.bd_fields = tmp;
        tmp = JSON.parse(result.body[0].dadata_fields);
        fields.dadata_fields = tmp;
        tmp = JSON.parse(result.body[0].name_titles);
        fields.name_titles = tmp;
        return fields;
      }
      return false;
    },
    async postOrgsSyncFields({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:orgdata_sync';
      const result = await Api.sendLargeJson(data);
      if (result && result.errorType === 0) {
        const newData = await dispatch('getOrgsSyncFields', params);
        return newData;
      }
      return false;
    },
  },
  mutations: {
    SET_ORG_JSON(state, data) {
      state.orgJson = data;
    },
    SET_CONTRACT_TYPES(state, data) {
      state.contractTypes = data;
    },
    SET_DOERS(state, data) {
      state.doers = data;
    },
    SET_FULL_ORGS_LIST(state, data) {
      state.fullOrgsList = data;
    },
  },
  getters: {
    contractTypes(state) {
      return state.contractTypes;
    },
    doers(state) {
      return state.doers;
    },
    // getFullOrgsList(state) {
    //   return state.fullOrgsList;
    // },
  },
};
