<template>
  <mcw-dialog-title>
    Управление учетными записями
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    <template v-if="loginGroups.length">
    <h3>Выберите группу для учетной записи:</h3>
     <mcw-data-table style="overflow-x: auto">
        <table class="mdc-data-table__table contracts__table">
          <thead class="contracts__table-head">
            <tr
              class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell">
                2-ФА
              </th>
              <th class="mdc-data-table__header-cell">
                Название
              </th>
              <th class="mdc-data-table__header-cell">
                Бенефициар
              </th>
              <th class="mdc-data-table__header-cell">
                Заказчик/Плательщик
              </th>
              <th class="mdc-data-table__header-cell"></th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content">
            <tr class="mdc-data-table__row contracts__row newlogin__row"
              v-for="(group, idx) in loginGroups"
              :key="group.id"
              :class="{newlogin__row_selected: selectedGroup && selectedGroup.id === group.id}"
              @click="selectGroup(idx)">
              <td class="mdc-data-table__cell contracts__cell">
                {{group.need_2fa ? 'есть' : 'нет'}}
              </td>
              <td class="mdc-data-table__cell contracts__cell">{{group.group_name}}</td>
              <td class="mdc-data-table__cell contracts__cell">{{group.ben}}</td>
              <td class="mdc-data-table__cell contracts__cell">{{group.cust}}</td>
              <mcw-material-icon icon="check" class="newlogin__marker"></mcw-material-icon>
            </tr>
          </tbody>
        </table>
      </mcw-data-table>
      </template>
      <mcw-button
      @click='createNewGroup'>
      {{secondHeading}}</mcw-button>
      <section :class="{newGroup: !creatingNewGroup}">
        <div class="textfield-container">
          <mcw-textfield v-model="newGroup.name" label="Название группы" outline/>
        </div>
        <div class="textfield-container">
          <mcw-select
            class="select_dense"
            v-model="newGroup.contract"
            outlined
            label="Договор">
            <mcw-list-item
              v-for="contract of contracts"
              :key="contract.id_contract"
              :data-value="contract.id_contract"
              >{{ contract.name_contract }}</mcw-list-item>
          </mcw-select>
        </div>
        <div class="textfield-container">
          <mcw-checkbox
            v-model="newGroup.twoFactorAuth"
            label="Нужна ли 2-факторная авторизация"
          />
        </div>
        <mcw-button
          @click="saveGroup"
          :disabled="!isGroupDataValid">
          Сохранить группу
        </mcw-button>
      </section>
      <section :class="{newGroup: creatingNewGroup}">
        <p v-if="selectedGroup">
          Учетная запись будет добавлена в группу {{selectedGroup.group_name}}
        </p>
        <form action="" class="newlogin">
          <div class="form-group" v-if='contact.id_people === 0'>
            <mcw-checkbox
              v-model="isFilled"
              :disabled="!selectedGroup"
              label="Не заполнять персональные данные"/>
          </div>
          <div class="form-group">
          <mcw-textfield
            v-if='contact.id_people === 0'
            :disabled="!selectedGroup || isFilled"
            :required="!isFilled"
            v-model="form.people_lname"
            label="Фамилия"
            outline/>
          <mcw-textfield
            v-if='contact.id_people === 0'
            :disabled="!selectedGroup || isFilled"
            v-model="form.people_fname"
            required
            label="Имя"
            outline />
          </div>
          <div class="form-group">
          <mcw-textfield
            v-if='contact.id_people === 0'
            v-model="form.people_mname"
            :disabled="!selectedGroup || isFilled"
            label="Отчество"
            outline />
          <mcw-textfield
            ref='bdInput'
            v-if='contact.id_people === 0'
            v-model="form.people_bd"
            :disabled="!selectedGroup || isFilled"
            label="Дата рождения"
            v-date='true'
            outline />
          </div>
          <div class="form-group form-group_centered" v-if='contact.id_people === 0'>
            <span>Пол:</span>
            <mcw-radio
              :disabled="!selectedGroup || isFilled"
              v-model="form.people_sex"
              label="М"
              name="gender"
              value="1"
            ></mcw-radio>
            <mcw-radio
              :disabled="!selectedGroup || isFilled"
              v-model="form.people_sex"
              label="Ж"
              name="gender"
              value="2"
            ></mcw-radio>
          </div>
          <div class="form-group form-user-group">
              <mcw-textfield
                :disabled="!selectedGroup"
                v-model="newLogin.login"
                helptext="Минимум 3 символа"
                required
                :minlength="3"
                helptext-validation
                label="Логин"
                outline/>
              <mcw-select
                class="select-user-type"
                v-model="selectedType"
                outlined
                label="Тип учетки">
                <mcw-list-item
                  v-for="type of loginTypes"
                  :key="type.value"
                  :data-value="type.value"
                  >{{ type.name }}</mcw-list-item>
              </mcw-select>
          </div>
          <section class="login-variants-chips" v-if="loginVariants">
            <span>Варианты логина:</span>
            <mcw-chip-set choice>
              <mcw-chip
                v-for="login in loginVariants"
                :key="login.login_name"
                @click='setLoginVariant(login.login_name)'
              >{{ login.login_name }}</mcw-chip>
            </mcw-chip-set>
          </section>
        </form>
      </section>
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button
      @click.prevent="saveLogin()"
      :disabled="!isPossibleToSave">
      Сохранить
    </mcw-dialog-button>
    <mcw-dialog-button action="dismiss">Выйти</mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LoginCreate',
  props: {
    contact: Object,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      newGroup: {
        name: '',
        contract: '',
        twoFactorAuth: false,
      },
      newLogin: {
        login: '',
      },
      form: {
        people_lname: '',
        people_fname: '',
        people_mname: '',
        people_bd: '',
        people_sex: '',
      },
      loginTypes: [
        { name: 'Пользователь', value: 1 },
        { name: 'Ответственный', value: 2 },
      ],
      selectedGroup: null,
      isFilled: false,
      loginVariants: null,
      hasVariants: false,
      creatingNewGroup: false,
      selectedType: null,
    };
  },
  computed: {
    loginGroups() {
      return this.$store.getters['loginData/getLoginGroups'];
    },
    contracts() {
      return this.$store.getters['contractsData/getContracts'];
    },
    secondHeading() {
      let text = this.loginGroups.length ? 'или создайте новую группу' : 'Создайте группу для учетной записи';
      if (this.creatingNewGroup) text = 'Назад к управлению записью';
      return text;
    },
    isGroupDataValid() {
      return this.newGroup.name && this.newGroup.contract;
    },
    isPossibleToSave() {
      const dt = moment().diff(moment(this.form.people_bd, 'DD-MM-YYYY'), 'years', false);
      let dateValid;
      if (!Number.isNaN(dt) && dt > 17 && dt < 120) {
        dateValid = true;
      } else dateValid = false;
      if (this.contact.id_people !== 0 && this.newLogin.login.length >= 3 && this.selectedType && dateValid) {
        return true;
      }
      return this.newLogin.login.length >= 3 && this.selectedType;
    },
    tryToGetLoginVariants() {
      return (this.form.people_lname.length >= 2 && this.form.people_fname.length >= 2 && this.form.people_mname.length >= 2);
    },
  },
  watch: {
    isFilled(bool) {
      if (bool) this.showLoginsVariants();
    },
    tryToGetLoginVariants(bool) {
      if (bool) this.showLoginsVariants();
    },
  },
  methods: {
    selectGroup(idx) {
      this.selectedGroup = this.loginGroups[idx];
      if (this.contact.id_people !== 0) this.showLoginsVariants();
      if (this.$refs.bdInput) {
        this.$nextTick(() => {
          this.$refs.bdInput.focus();
          this.$refs.bdInput.input.blur();
        });
      }
    },
    createNewGroup() {
      this.creatingNewGroup = !this.creatingNewGroup;
      return this.creatingNewGroup;
    },
    async saveGroup() {
      const params = {
        id_contract: this.newGroup.contract,
        text: this.newGroup.name,
        need_2fa: this.newGroup.twoFactorAuth ? 1 : 0,
      };
      const newGroup = await this.$store.dispatch('loginData/saveLoginGroup', params);
      if (newGroup) {
        await this.$store.dispatch('loginData/getLoginGroups');
        this.emitter.emit('message', `Группа ${this.newGroup.name} успешно создана`);
        this.selectGroup(this.loginGroups.length - 1);
      }
    },
    async saveLogin() {
      let jsonStr2 = '';
      const jsonStr = JSON.stringify({
        login: this.newLogin.login,
        id_contact: this.contact.id_contact,
        login_type: +this.selectedType,
      });
      if (!this.isFilled && this.contact.id_people === 0) {
        const formArr = Object.values(this.form);
        if (formArr.some((item) => item === '')) {
          this.emitter.emit('message', 'Заполните все поля формы');
          return;
        }
        jsonStr2 = JSON.stringify(this.form);
      }
      const params = {
        id_login_group: this.selectedGroup.id,
        id_parametr: this.isFilled ? 1 : 0,
        json_str: jsonStr,
        json_str2: jsonStr2,
      };
      const newLogin = await this.$store.dispatch('loginData/createLogin', params);
      if (newLogin) {
        this.emitter.emit('message', 'Учетка успешно создана');
        this.$store.dispatch('contractsData/getResponsiblings');
        this.$emit('close');
        this.$store.dispatch('contractsData/getResponsiblings');
      } else {
        this.emitter.emit('message', 'Не удалось создать учетку');
      }
    },
    async showLoginsVariants() {
      const jsonStr = {
        id_contact: this.contact.id_contact,
      };
      Object.assign(jsonStr, this.form);
      const params = {
        id_org: this.$store.state.user.selectedCompany.id_org,
        id_people: this.contact.id_people,
        json_str: JSON.stringify(jsonStr),
      };
      this.loginVariants = await this.$store.dispatch('loginData/getLoginVariant', params);
      this.hasVariants = !!this.loginVariants.length;
    },
    setLoginVariant(login) {
      this.newLogin.login = login;
    },
    // detectContract(contract) {
    //   if (contract === 'все контракты') {
    //     return contract;
    //   }
    //   const contractArr = contract.split(',');
    //   if (contractArr.length) {
    //     // return contractArr
    //     //   .map((item) => this.contracts
    //     //     .find((el) => el.id_contract === +item).name_contract).join(', ');
    //   }
    //   return '';
    // },
  },
};
</script>

<style lang="scss">
@use "@material/chips/deprecated/mdc-chips";
.newGroup {
  display: none;
}
.select_dense {
  margin-top: 0.5rem;
}
.newlogin{
  &__row{
    margin: 0 24px;
  }
  &__row_selected{
    position: relative;
    background-color: rgba(green, 0.08);
    & .newlogin__marker{
      display: block;
    }
  }
  &__marker{
    display: none;
    position: absolute;
    left: -24px;
  }
}
.form-group{
  display: flex;
  justify-content: space-between;
  &_centered{
    justify-content: center;
    align-items: center;
  }
}
.form-user-group .select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
